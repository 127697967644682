@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.extras {
  &__seats {
    @include gap(16px);

    display: flex;
    cursor: pointer;

    &--disabled {
      cursor: initial;
    }

    &-info {
      @include gap(4px, true);
    }

    &-description {
      @include font(14px, 20px, 400);
      @include gap(4px, true);

      color: $color-seats-description;

      span {
        font-family: $font-secondary;
      }

      @include on-desktop {
        @include gap(0, true);
        @include gap(8px);
      }
    }

    &-icon {
      margin-left: auto;
      color: $color-seats-icon;
    }

    &-stations {
      @include gap(12px);
      @include font(16px, 24px);

      display: flex;
      margin-top: 14px;

      &-leg {
        @include font(16px, 24px, 400);
      }

      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .font-icon {
        font-weight: 400;
      }

      * {
        font-family: $font-secondary;
      }
    }

    &-warning-message {
      @include font(12px, 16px, 400);

      color: $color-error;
    }
  }
}
