@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.partner-logo {
  display: flex;

  .logo-image {
    display: block;
    max-width: 100%;
    max-height: 56px;

    @include on-mobile {
      max-height: 50px;
    }
  }

  .powered-by-distribusion-image {
    position: relative;
    top: 5px;
  }
}
