@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.extras {
  &__card {
    display: flex;

    h4 {
      margin-bottom: 8px;
    }

    img {
      transform: scale(0.8);

      @include on-desktop {
        transform: scale(1);
      }
    }

    &-icon {
      color: $color-seats-icon;

      @include on-desktop {
        transform: rotate(90deg);

        &--opened {
          transform: rotate(270deg);
        }
      }
    }

    &-description {
      @include font(14px, 20px, 400);
      @include gap(8px, true);

      font-family: $font-secondary;
      color: $color-extras-ancillaries-description;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;

      span {
        font-family: $font-secondary;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &--clickable {
          cursor: pointer;
        }

        &--grouped {
          background: $color-extras-card-group;
          padding: 12px 20px;
          border-radius: 8px;
        }
      }
    }

    &-price {
      @include font(16px, 24px, 600);

      color: $black;

      span {
        margin-right: 6px;
      }

      * {
        font-family: $font-secondary;
      }
    }

    &-controls {
      margin-left: auto;
    }

    &-modal {
      &-content {
        padding: 0 20px 20px;

        :last-child {
          border: none;
        }
      }

      &-buttons {
        padding: 0 20px 20px;

        div {
          flex-basis: 50%;
        }
      }

      .ui-modal__header,
      .ui-modal__footer {
        box-shadow: none;
      }
    }

    &-learn {
      @include font(12px, 16px, 600);

      cursor: pointer;
      color: $color-extras-learn-text;

      * {
        font-family: $font-secondary;
      }

      .font-icon {
        color: $color-extras-learn-icon;
        font-weight: 400;
        margin-bottom: 4px;

        @include on-desktop {
          transform: translateY(2px);
        }
      }

      &-description {
        @include font(14px, 22px, 400);
      }
    }
  }

  &__add {
    display: flex;
    align-items: center;

    &-text {
      margin-left: 8px;
    }
  }

  &__ancillary {
    @include font(16px, 24px, 600);

    color: $color-extras-accommodation-select-fg;
    cursor: pointer;

    * {
      font-family: $font-secondary;
    }

    &--selected {
      display: flex;
      align-items: center;
      padding: 4px 12px;
      border-radius: 4px;
      background: $color-extras-ancillary-selected-bg;
      border: 1px solid $color-extras-ancillary-selected-border;

      * {
        color: $color-extras-ancillary-selected-fg;
      }

      span {
        @include font(14px, 18px, 600);

        font-family: $font-secondary;
      }

      .font-icon {
        font-weight: 500;
        transform: translateY(2px);
      }
    }
  }
}
