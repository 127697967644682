@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.weekly-list {
  &__location {
    &-outbound,
    &-inbound {
      @include font(16px, 24px, 500);

      background-color: $color-grey-10;
      padding: 10px;

      & .font-icon-arrow-right {
        display: inline-block;
        transform: rotate(90deg);
        line-height: 12px;
        margin: 0 auto;

        @include on-desktop {
          margin: unset;
          transform: translateY(5px);
        }
      }
    }

    &-inbound {
      border-left: 1px solid $color-weekly-list-border;

      @include on-desktop {
        border-left: none;
      }
    }

    &-name {
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;

      @include on-desktop {
        white-space: nowrap;
        text-align: unset;
      }
    }
  }

  &__filler {
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    background: $white;
  }
}
