@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
// We can't use transitions inside the ::-webkit-scrollbar, so the workaround is to use property transition feature
@property --scroll-thumb-color {
  syntax: '<color>';
  inherits: true;
  initial-value: rgb(0 0 0 / 0%);
}

@property --scroll-rail-color {
  syntax: '<color>';
  inherits: true;
  initial-value: rgb(0 0 0 / 0%);
}

@include media('on-tablet', 'on-desktop') {
  .train-seats {
    padding: 0 40px;
    display: flex;
    min-height: 0;
    flex: 1;

    &__deck-section {
      overflow: visible scroll;
      max-height: 100%;
      transition:
        --scroll-thumb-color 0.2s,
        --scroll-rail-color 0.2s;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        cursor: pointer;
        background-color: var(--scroll-rail-color);
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 8px;
        background-clip: content-box;
        background-color: var(--scroll-thumb-color);
      }

      &:hover {
        --scroll-rail-color: rgb(0 0 0 / 5%);
        --scroll-thumb-color: rgb(0 0 0 / 22%);
      }
    }
  }

  .train-seats-modal {
    .ui-modal__header {
      box-shadow: none;

      .font-icon-info {
        color: $color-secondary-70;
      }
    }

    .ui-modal__content {
      display: flex;
      flex-direction: column;
    }
  }
}
