@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-card {
  &__header {
    @include font;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 12px;

    @include on-desktop {
      padding: 20px 32px 16px;
    }

    &-logo {
      @include gap(8px);

      display: flex;
      height: 26px;

      @include on-desktop {
        height: 32px;
      }

      &-img {
        max-width: 100%;
        border-radius: 4px;
      }
    }

    &-logo-count {
      color: $color-card-amount;

      @include on-desktop {
        @include font(14px, 20px);
      }
    }

    &-trip-type {
      &-icon {
        color: $color-secondary-80;
        margin-right: 8px;
      }

      flex-shrink: 0;
      display: flex;
      flex-basis: 30%;
      text-align: end;

      @include font;

      @include on-desktop {
        @include font(14px, 20px);
      }
    }
  }
}
