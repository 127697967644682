@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.virtual-list {
  &__item {
    padding-left: 7px;
    padding-right: 5px;

    &:first-child {
      padding-top: 5px;
    }
  }
}
