@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: $header-line-search-height-mobile;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;

  @include on-desktop {
    height: $header-line-search-height-desktop;
  }
}

.background-block {
  @include on-mobile {
    display: none;
  }
}

.background-offset {
  height: 160px;

  @include on-mobile {
    display: none;
  }
}
