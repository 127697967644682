@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.custom-pay-button {
  width: 100%;
  height: 56px;

  .adyen-checkout__paywithgoogle {
    height: inherit;
  }

  .adyen-checkout__applepay__button {
    width: 100%;
    height: inherit;
  }
}
