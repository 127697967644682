@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.vgs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  &-field__wrapper {
    &:first-child,
    &:nth-child(4) {
      grid-column: 1 / span 2;
    }
  }
}
