@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.trip-connection-details {
  &__heading {
    margin-bottom: 16px;
  }

  &__subheading {
    margin-bottom: 12px;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__info {
    display: flex;
    gap: 20px;

    .info-item {
      display: flex;
      align-items: center;
      gap: 8px;

      &__icon {
        width: 20px;
        height: 20px;
        color: $color-grey-50;
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
