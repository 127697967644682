@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$desktop-weekly-width: 1230px;

.weekly-list {
  display: grid;
  grid-auto-flow: row;
  box-shadow: $box-shadow-3-bottom;

  * {
    font-family: $font-secondary;
  }

  @include on-desktop {
    grid-auto-flow: column;
    grid-template-columns: minmax(28%, 40%) auto;
    box-shadow: none;
    overflow: auto;
    padding: 0 2px 0 5px;
  }

  &__wrapper {
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .font-icon-info {
      color: $color-weekly-list-info;
    }

    @media only screen and (min-width: $screen-md + 1px) and (max-width: $desktop-weekly-width) {
      padding: 0 24px;
    }
  }

  &__section {
    border-radius: 24px;
    padding: 21px;
    box-shadow: $box-shadow-3-bottom;
    background: $white;
    margin-bottom: 115px;

    @include on-desktop {
      margin-bottom: 0;
      border-radius: 12px;
    }
  }

  &__info-modal {
    & .ui-modal__header {
      box-shadow: none;
    }

    & .ui-modal__content {
      padding: 0 20px 20px;
    }

    h2 {
      margin: 0;
    }
  }

  &__time-modal {
    & .ui-modal__header {
      padding: 16px 24px;
    }

    & .ui-modal__content .ui-option {
      padding: 12px 24px;
    }
  }

  &__submit {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    padding: 16px 20px;
    box-shadow: $box-shadow-1-top;
    width: 100%;
    background: $white;
    margin-left: auto;

    @include on-desktop {
      position: static;
      z-index: unset;
      padding: 0;
      box-shadow: unset;
      width: unset;
    }
  }

  &__error {
    color: $color-error;
  }

  &__booked {
    @include font(12px, 16px, 500);

    padding-left: 10px;

    div {
      background: $color-weekly-list-booked;
      color: $white;
      padding: 10px;
      border-radius: 8px;
    }
  }
}
