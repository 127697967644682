@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.trip-conditions {
  margin-top: 12px;

  &__title {
    @include font(14px, 20px, 500);

    color: $color-journey-card-conditions;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 6px 16px 6px 12px;
    background: $color-fare-class-bg;
    border: 1px solid $color-border-fare-class;
    border-radius: 4px;
    width: fit-content;
  }

  &__icon {
    display: flex;
    color: $color-journey-card-conditions;
    padding-right: 8px;
  }

  &__description {
    @include font(12px, 16px, 400);

    font-family: $font-secondary;
  }
}
