@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  &__card-dropdown.cell {
    max-width: 280px;
    flex: 0 0 280px;
  }

  &__legend {
    padding: 0 20px;
    width: 100%;

    @include on-desktop {
      justify-content: space-between;
      padding: 10px;
      margin-top: 0;
    }

    &-info {
      @include font(12px, 16px, 400);

      order: 2;

      @include on-desktop {
        @include font(14px, 20px, 400);

        order: 1;
        margin-right: 20px;
      }
    }

    &-amenities {
      margin: 24px 0;
      flex-wrap: wrap;

      @include on-desktop {
        transform: translateY(3px);
        order: 2;
        justify-content: end;
        margin: 0;
        flex-shrink: 0;

        .ui-tooltip {
          height: max-content;
          cursor: default;
        }

        & .journey-card__footer-amenities-icon {
          cursor: default;
        }
      }
    }

    &-discount-chip {
      position: absolute;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      font-size: 10px;
      line-height: 12px;
      background-color: $color-secondary-60;
      top: -4px;
      right: -4px;
    }

    &-seat {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 4px;
      background: $color-seat-available;
      cursor: pointer;
      margin-right: 8px;

      &--disabled {
        border: 1px $color-seat-disabled solid;
        background: transparent;
        cursor: initial;
        pointer-events: none;

        .font-icon {
          color: $color-seat-icon-disabled;
        }
      }

      &--selected {
        background: $color-seat-selected;

        .font-icon {
          color: $white;
        }
      }

      &--paid {
        background: $color-seat-paid;
      }

      &--discounted {
        background: $color-seat-discounted;
        color: $white;
        font-weight: 600;
      }

      .font-icon-dollar,
      .font-icon-euro,
      .font-icon-pound {
        &.seat-selection__legend-seat-sign {
          color: $white;
          font-size: 10px;
          width: 7px;
          height: 10px;
          line-height: 20px;
        }
      }

      .font-icon-star-simple {
        &.seat-selection__legend-seat-sign {
          color: $white;
          font-size: 7px;
          width: 7px;
          height: 7px;
        }
      }
    }

    &-discounts {
      @include font(12px, 16px, 500);

      color: $color-warning;
      white-space: nowrap;
    }
  }
}
