@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-list {
  &__emission {
    &-details {
      * {
        font-family: $font-secondary;
      }

      &-header {
        @include font(17px, 22px, 600);

        text-align: start;
        padding: 20px 20px 20px 16px;

        span {
          font-family: $font-primary;
        }
      }

      &-body {
        @include font(14px, 20px, 400);

        background: $color-journey-list-emission-bg;
        padding: 16px 20px 10px;

        hr {
          width: 100%;
          border: none;
          border-top: 1px solid $color-grey-20;
        }
      }

      &-footer {
        @include font(12px, 16px, 400);

        color: $color-journey-list-emission-fg;
        padding: 16px 20px 20px;
        text-align: justify;
      }
    }
  }
}
