$color-checkbox-tick: white;
$color-checkbox-outline-focused: $color-secondary-70;
$color-checkbox-unchecked: $color-grey-50;
$color-checkbox-unchecked-hover: $color-secondary-70;
$color-checkbox-unchecked-disabled: $color-grey-40;
$color-checkbox-checked: $color-secondary-70;
$color-checkbox-checked-hover: $color-secondary-60;
$color-checkbox-checked-disabled: $color-grey-40;
$color-checkbox-error: $color-error;
$color-message-bg: linear-gradient(0deg, rgb(255 255 255 / 95%) 0%, rgb(255 255 255 / 95%) 100%), #231a87;
$color-message-border: $color-secondary-30;
