@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.transport-mode {
  & .ui-tabs__header-wrapper {
    padding-top: 8px;
    display: flex;
    flex: 1;

    div {
      flex: 1;
      margin-right: 0;
    }
  }

  &__tabs {
    * {
      font-family: $font-secondary;
    }

    &-title {
      @include font(16px, 24px, 600);

      margin-bottom: 4px;
    }

    &-description {
      @include font(12px, 16px, 400);

      @include on-desktop {
        @include font(14px, 20px, 400);
      }
    }
  }

  &__divider::after {
    content: '\00B7';
    width: 2px;
    height: 2px;
    margin: auto 4px;

    @include on-desktop {
      width: 4px;
      height: 4px;
    }
  }
}
