@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.media-carousel {
  &__navigate-dots {
    display: flex;
    margin-top: 12px;
    justify-content: center;
  }

  &__navigate-dot {
    padding: 6px;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-grey-30;
    }

    &--active {
      &::before {
        background-color: $color-secondary-70;
      }
    }
  }
}
