@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.terms-content {
  @include font(14px, 22px, 400);

  font-style: normal;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  line-height: 22px;
  margin: 0;

  * {
    font-family: $font-secondary;
  }

  ul,
  ol,
  p {
    margin: 0;
    display: table;
  }

  h3 {
    margin-bottom: 12px;
  }
}
