@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.booking-details {
  hr {
    border: none;
    border-top: 1px solid $color-grey-20;
    margin: 20px 0;
  }

  .booking-details__terms {
    @include font(14px, 20px, 400);
  }

  &__trip {
    color: $color-booking-trip;
  }

  &__info {
    @include font(16px, 24px, 400);
  }

  &__amount {
    font-weight: 600;
  }
}
