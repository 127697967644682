@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.page {
  height: 100%;
  width: 100%;

  &__header {
    @include gap(24px);

    display: flex;
    padding: 16px 20px;
    align-items: center;

    .header {
      &__title {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $black;
      }
    }
  }

  &__content {
    height: calc(100% - 56px);
    overflow: scroll;

    .list {
      &__item {
        @include gap(16px);

        display: flex;
        align-items: center;
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 20px;

        &--separated {
          border-bottom: 1px solid $color-dropdown-currency-border;
        }

        &--selected {
          font-weight: 600;
        }

        .item__icon {
          width: 30px;
          font-weight: 600;
        }

        .item__name {
          flex: 1;
          margin-left: 40px;
        }
      }
    }
  }
}
