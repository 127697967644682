@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$weekly-list-header-height: 55px;
$weekly-list-cell-width: 115px;
$weekly-list-stations-width: 280px;
$weekly-list-date-mobile: 24px;

.weekly-list {
  &__column {
    display: grid;
    grid-template:
      'date copy' $weekly-list-date-mobile
      'departure-time return-time' $weekly-list-header-height;
    padding: 16px 20px 0;
    border: 1px solid $color-weekly-list-border;
    margin-bottom: -1px;

    @include on-desktop {
      min-width: $weekly-list-cell-width;
      grid-template: none;
      grid-template-rows: repeat(3, auto);
      padding: 0;
      border: none;
      margin-bottom: 0;
    }

    &--returns {
      @include on-desktop {
        grid-template-rows: repeat(4, auto);
      }
    }

    &--header {
      grid-template: repeat(1, auto) / repeat(auto-fit, minmax(100px, 1fr));
      padding: 0;
      border: none;

      @include on-desktop {
        min-width: $weekly-list-stations-width;
        grid-template: 1fr / 100%;
      }
    }

    &--fixed {
      position: sticky;
      left: 0;
      z-index: 10;
    }
  }
}
