@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.stations {
  @include gap(8px);

  display: flex;

  * {
    font-family: $font-secondary;
  }

  &__time {
    @include font(16px, 24px);

    flex: 0 0 auto;
    flex-basis: 14%;
    max-width: 14%;

    &-duration {
      @include font(12px, 16px, 500);

      color: $color-card-amount;
      margin: auto;
      white-space: nowrap;
    }
  }

  &__transport {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-journey-card-icon-fg;

    &-divider {
      width: 1px;
      height: 100%;
      background: $color-journey-card-icon-fg;
      margin: 10px 0;
      padding-bottom: 20px;

      &__icon::before {
        transform: translate(-50%, 10px);
        background: #fff;
        padding: 6px;
      }
    }
  }

  &__term {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-departure,
    &-arrival {
      @include font(16px, 24px);
    }

    &-description {
      @include font(12px, 16px, 400);

      padding-top: 4px;
    }

    &-icon {
      color: $color-card-time;
    }
  }
}
