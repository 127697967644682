@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-accordion {
  &__header {
    cursor: pointer;
    padding: 20px 0;
  }

  &__expand {
    .font-icon {
      transition: transform 0.3s ease;
    }

    &.open {
      .font-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__content-wrapper {
    min-height: 0;
    transition: min-height 0.3s ease;
    overflow: hidden;
  }

  hr {
    margin: 0;
  }

  &--card {
    background-color: $white;
    color: $black;
    border-radius: 8px;
    padding: 24px 32px;
  }
}
