@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.banner-block {
  position: relative;
  z-index: 100;
  background-color: $color-primary-70;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    max-width: 1200px;
    font-weight: 600;
    color: $color-background;
    gap: 16px;
  }
}
