@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.cards-block {
  &__description {
    max-width: 790px;
  }

  &.center {
    align-items: center;
    text-align: center;

    .block-card__image {
      align-self: center;
    }
  }

  &.image-size-icon {
    .block-card__image {
      width: map-get($gap-values, 8);
      height: map-get($gap-values, 8);
    }
  }
}
