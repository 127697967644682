@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.subscribe-block {
  &__privacy-policy {
    filter: brightness(95%);
  }

  &__link {
    cursor: pointer;
    filter: unset;
    text-decoration: underline;
  }
}
