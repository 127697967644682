$color-extras-ancillaries-description: $color-grey-80;
$color-extras-section-border: $color-grey-20;
$color-extras-learn-icon: $color-secondary-60;
$color-extras-learn-text: $color-secondary-60;
$color-extras-ancillary-selected-bg: #e6f5ea;
$color-extras-accommodation-select-fg: $color-secondary-70;
$color-extras-ancillary-selected-border: #b3e1bd;
$color-extras-ancillary-selected-fg: $color-primary-70;
$color-extras-accommodation-warning: $color-error;
$color-extras-accommodation-warning-close: $color-grey-60;
$color-extras-card-group: $color-grey-10;
$color-extras-voucher: $color-secondary-70;
$color-extras-alert-bg: #ffe7e7;
$color-extras-alert-fg: $color-grey-90;
$color-extras-alert-icon-bg: #c83636;
