@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.trip-transfer-time {
  @include font(16px, 24px, 400);

  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-transfer-time-bg;
  border-radius: 8px;
  padding: 12px 55px;
  margin-top: 20px;
  position: relative;
  font-family: $font-secondary;

  &__transfer-icon {
    color: $color-detailed-fare-icon;
    margin-right: 14px;
  }

  &__station-change {
    position: absolute;
    left: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    background-color: $color-secondary-80;
    padding: 4px 8px;
    border-radius: 8px;
    color: $white;

    &-icon {
      color: $white;
      display: inline;
      margin-right: 10px;
    }
  }
}
