@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.price-filter {
  &__divider {
    line-height: 56px;
  }

  &__bound {
    @include font(12px, 16px, 400);

    color: $color-grey-70;
    margin-top: 4px;
  }

  .ui-input {
    padding-right: 4px;
  }
}
