@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-card {
  &__subfooter {
    font-family: $font-secondary;
    padding: 20px;
    z-index: 1;

    @include on-desktop {
      box-shadow: 0 12px 16px rgb(11 8 39 / 8%);
      border-radius: 0 0 24px 24px;
      padding: 20px 32px;
    }
  }
}
