@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.checkout {
  padding-bottom: 48px;

  @include on-mobile {
    padding-bottom: 0;
  }

  #bg-line.dense {
    height: 80px;
  }

  .back-button.embed {
    color: $black;
  }

  &__section {
    position: relative;
    padding: 20px;
    background-color: $white;
    box-shadow: 0 2px 16px rgb(11 8 39 / 8%);
    border-radius: 12px;

    @include on-desktop {
      padding: 24px 32px;
    }
  }

  &__message {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    background: $color-message-bg;
    border: 1px solid $color-message-border;

    @include on-desktop {
      padding: 24px 32px;
    }

    ul,
    p {
      margin: 0;
    }

    li:not(:last-child),
    p {
      margin-bottom: 10px;
    }

    * {
      font-size: 14px;
      line-height: 20px;
      font-family: $font-secondary;
    }
  }

  &__content {
    @include on-mobile {
      background-color: $white;
      padding: 20px;
    }
  }
}

.checkout-wrapper {
  @include on-mobile {
    padding-bottom: 104px;
  }
}

#three-d-secure-iframe-portal {
  display: flex;

  iframe {
    width: 100%;
  }
}
