@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.custom-blocks:has(.trip-search-block--omitOffset) {
  .background-offset {
    display: none;
  }
}

.trip-search-block {
  padding: 0;

  @include on-mobile {
    padding: 0 20px 20px;
  }
}

.trip-search-block__wrapper {
  z-index: 10;
  position: relative;
}
