@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.cookies-consent {
  position: fixed;
  z-index: $z-index-page-foreground;
  background-color: white;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 20px 24px;
  box-shadow: 0 4px 16px 0 #0b082729;

  @include on-desktop {
    border-radius: 12px;
    padding: 24px 32px;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 986px;
  }

  &__actions.cell {
    flex-grow: 0;

    @include on-desktop {
      flex-basis: 285px;
    }
  }

  &__title {
    margin: 0;
  }
}
