@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@import '@ui/Input';

.vgs-field {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__field {
    @extend .ui-input;

    height: 56px;
    padding: 0 16px;

    &--invalid {
      @extend .ui-input-error;
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &__error-message {
    @extend .ui-input-error-message;

    display: flex;
    align-items: center;
    gap: 8px;
  }
}
