$color-carousel-border: #e5e5e8;
$color-carousel-bg: $white;
$color-carousel-fg-primary-text: $color-grey-80;
$color-carousel-fg-secondary-text: $color-grey-80;

$color-carousel-bg-selected: $color-secondary-70;
$color-carousel-fg-primary-text-selected: $white;
$color-carousel-fg-secondary-text-selected: $white;

$color-carousel-bg-selected-hover: $color-secondary-60;
$color-carousel-border-hover: $color-secondary-70;

$color-carousel-border-disabled: #e5e5e8;
$color-carousel-fg-primary-text-disabled: $color-grey-60;
