@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.fare-class {
  &__class-section,
  &__family-section {
    position: relative;
    overflow: auto;
    padding-right: 16px;
    min-height: 285px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__family-section {
    min-height: 210px;
  }

  &__description {
    ul {
      margin: 0 0 0 17px;
    }

    li {
      @include font(14px, 20px, 400);

      font-family: $font-secondary;
    }
  }

  &__wrapper {
    @include gap(20px, true);

    & .ui-accordion {
      border-radius: 12px;

      &__header {
        padding: 24px 32px;
        background-color: $color-fare-class-accordion-bg;
        border-radius: 12px 12px 0 0;

        h3,
        h4 {
          margin-bottom: 0;
        }

        div {
          align-items: center;
        }

        .font-icon {
          color: $color-fare-class-accordion-icon;
          font-weight: 400;
        }
      }

      &__content {
        & div:first-child .checkout__section {
          border-radius: 0;
          border-bottom: 1px solid $color-fare-class-accordion-bg;
        }

        & .checkout__section {
          box-shadow: none;
          border-radius: 0 0 12px 12px;
        }

        &-wrapper {
          border-radius: 0 0 12px 12px;
          box-shadow: $box-shadow-3-bottom;
        }
      }
    }
  }
}
