@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.on-hold-reservation {
  padding: 32px;

  &__data {
    width: 100%;
    background-color: $color-primary-10;
    padding: 8px 24px 16px 16px;
    border-radius: 6px;
  }

  &__controls {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
