@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.media-carousel--desktop {
  .media-carousel__media {
    position: relative;
    max-height: 600px;
    border-radius: 12px;
    overflow: hidden;

    .media-carousel__media-container {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      overflow: visible;

      &--forward {
        animation: 0.75s ease forward forwards;

        @keyframes forward {
          from {
            transform: translateX(0%);
          }

          to {
            transform: translateX(-100%);
          }
        }
      }

      &--back {
        animation: 0.75s ease back forwards;

        .media-carousel__next-media {
          order: -1;
        }

        @keyframes back {
          from {
            transform: translateX(-100%);
          }

          to {
            transform: translateX(0%);
          }
        }
      }

      img,
      video,
      iframe {
        flex-shrink: 0;
        flex-basis: 100%;
        width: 100%;
        height: 100%;
        max-height: 600px;
      }

      iframe {
        height: 600px;
        border: 0;
      }
    }
  }

  &:hover {
    .media-carousel__navigate-arrows {
      opacity: 1;
    }
  }

  .media-carousel__navigate-arrows {
    opacity: 0;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    transition: opacity 0.25s;

    .ui-button-wrapper {
      cursor: pointer;

      .ui-button {
        background-color: $white;
        border-color: $color-grey-20;
        color: $color-grey-70;
      }
    }
  }
}
