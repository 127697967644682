@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.media-carousel--mobile {
  overflow: visible;

  .media-carousel__media {
    position: relative;
    display: flex;
    overflow: visible;
    gap: 8px;
    align-items: center;

    img,
    video,
    iframe {
      flex-shrink: 0;
      flex-basis: 100%;
      width: 100%;
      height: 100%;
      max-height: 600px;
      border: 0;
    }

    .media-carousel__media-container {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      overflow: visible;
    }

    @include on-mobile {
      flex-shrink: 1;
      flex-grow: 1;

      img,
      video,
      iframe {
        flex-shrink: 1;
        min-width: 100%;
      }
    }
  }
}
