@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.navigate-block {
  &__button {
    a {
      display: inline-flex;
    }

    .ui-button {
      min-width: 274px;
    }
  }

  &__image {
    order: -1;
    align-self: stretch;

    &.bottom,
    &.after {
      @include on-desktop {
        order: unset;
      }
    }

    img {
      width: 100%;
      border-radius: 6px;
    }
  }

  &.center {
    .navigate-block__text {
      align-items: center;
      text-align: center;
    }
  }

  // ToDo: Move to "Welcome" block
  &.custom-blocks__item--omit-offset {
    color: $color-background-text;
    margin-bottom: 24px;
    padding: 36px 0;

    @include on-mobile {
      padding: 36px 20px 0;

      .navigate-block__text {
        align-items: center;
        text-align: center;
      }
    }
  }
}
