@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.reservations-modal {
  &__homepage-text {
    color: $font-secondary;
  }

  &__content {
    padding: 0 32px 32px;
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;

    @include media(on-mobile) {
      flex-direction: column;
      justify-content: center;
      row-gap: 20px;
    }

    &-home {
      width: 248px;
      height: 56px;
      border: 1px solid #eeeef0;
      border-radius: 8px;
      color: $color-grey-10;
      box-shadow: 0 4px 8px rgb(13 11 35 / 7%);

      @include media(on-mobile) {
        width: auto;
      }
    }

    &-restart {
      width: 248px;
      height: 56px;

      @include media(on-mobile) {
        width: auto;
      }
    }
  }

  &__expired {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 40px;
  }

  &__message-title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  &__message-content {
    text-align: center;
    margin-bottom: 30px;
  }
}
