@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.form-block {
  &__description,
  &__title {
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  &--with-carrier {
    .form-block__description,
    .form-block__title {
      text-align: start;
    }
  }

  &__carrier-info-icon .font-icon {
    color: $color-grey-40;
    padding: 12px;
    border: 1px solid $color-grey-30;
    border-radius: 50%;
  }

  &--two-tone {
    &::before {
      content: '';
      inset: 0;
      position: absolute;
      width: 50%;
      z-index: -1;
      background-color: $color-secondary-10;

      @include on-mobile {
        display: none;
      }
    }
  }

  &__submit {
    align-self: start;

    .ui-button-wrapper {
      min-width: 264px;
    }

    @include on-mobile {
      width: 100%;
    }
  }

  &__carrier-email {
    word-break: break-word;
  }
}
