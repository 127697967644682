@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.timer-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  gap: 12px;
  height: 60px;
  background: linear-gradient(0deg, rgb(255 255 255 / 95%), rgb(255 255 255 / 95%)), #231a87;
  border-radius: 8px;

  &__loader {
    width: 10%;
  }
}
