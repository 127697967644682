@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@include on-mobile {
  .train-seats {
    width: 100%;
    padding-top: 12px;

    &__deck-section {
      flex-basis: auto;
      flex-shrink: 1;
      min-height: 0;

      &-scrollable {
        overflow: auto;
        max-height: 100%;
      }
    }
  }

  .train-seats-modal {
    .ui-modal__header {
      box-shadow: none;

      .font-icon-info {
        color: $color-secondary-70;
      }
    }

    .ui-modal__content {
      display: flex;
    }

    .ui-modal__footer {
      position: relative;
    }

    &__legend {
      .ui-modal__header {
        box-shadow: none;
      }

      & .seat-selection__legend-info {
        flex-direction: column;
      }
    }
  }
}
