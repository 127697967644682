@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.page-wrapper__content {
  min-height: 100vh;
  width: 100%;
}

.page-wrapper__header {
  background-color: var(--background);
  display: flex;
  justify-content: center;
}
