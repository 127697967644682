@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.train {
  &__selected-seat {
    border-radius: 6px;
    overflow-wrap: break-word;

    @include on-desktop {
      background: $color-train-seat-bg;
      padding: 12px 15px;
    }

    * {
      font-family: $font-secondary;
    }

    .font-icon {
      color: $color-train-icon;
    }
  }
}
