@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.result-page {
  max-height: 100vh;
  overflow: auto;
  position: relative;
  z-index: 10;

  #bg-line {
    z-index: -1;

    @include media(on-mobile, on-tablet) {
      z-index: -1;
    }
  }

  #page {
    min-height: 100vh;
    max-width: unset;

    @include media(on-mobile, on-tablet) {
      min-height: 100svh;
      max-height: 100vh;
      max-height: 100svh;
      overflow: hidden;
    }
  }

  &--weekly {
    #page {
      min-height: 80vh;
    }
  }

  &__header {
    @include on-desktop {
      position: sticky;
      top: 0;
      z-index: calc($z-index-dropdowns + 1);
    }
  }

  &__content.cell {
    flex: 1 1 auto;
    overflow: visible;
    min-height: 0;
    display: flex;
    flex-direction: column;

    @include media(on-mobile, on-tablet) {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__section,
  &__section.cell {
    @include on-desktop {
      max-width: $page-max-width;
      width: 100%;
      margin: 0 auto;
    }
  }
}
