@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@mixin divider($position) {
  content: '';
  position: absolute;
  top: -1px;
  #{$position}: -1px;
  width: 1px;
  height: calc(100% + 2px);
}

.ui-button-group {
  display: flex;
  width: 100%;
  gap: 1px;

  .ui-button-wrapper {
    flex-grow: 1;

    &:not(:first-child) {
      .ui-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;

        &::before {
          @include divider('left');
        }
      }
    }

    &:not(:last-child) {
      .ui-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;

        &::after {
          @include divider('right');
        }
      }
    }

    &.outline {
      .ui-button {
        &::before,
        &::after {
          background-color: $button-color-outline-border;
          z-index: 10;
        }
      }
    }

    .ui-button-shadow {
      display: none;
    }
  }

  &--flat {
    .ui-button-wrapper {
      .ui-button {
        border-radius: 0;
      }
    }
  }
}
