@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.subscribe-block {
  justify-content: center;

  @include on-desktop {
    &.row-lg {
      align-items: center;
    }

    &.column-lg {
      justify-content: center;
      text-align: center;
      max-width: 786px;
    }
  }

  @media only screen and (max-width: $screen-lg) {
    text-align: center;
  }
}
