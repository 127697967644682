@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-list {
  &__emission {
    @include font(12px, 16px, 400);

    font-family: $font-secondary;

    @include on-desktop {
      @include font(16px, 24px, 400);

      justify-content: end;
    }

    &-leaves {
      color: $color-journey-list-emission;
      margin-right: 8px;
    }

    &-info {
      color: $color-journey-list-info;
      margin-left: 8px;
      cursor: pointer;
    }

    &--enhance {
      font-size: inherit;
      font-family: $font-primary;
      color: $color-journey-list-emission;
      font-weight: 600;
    }

    &-tooltip {
      border-radius: 12px;
      max-width: 300px;
      max-height: unset;
      padding: 0;
    }
  }
}
