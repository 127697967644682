@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.block-card {
  &__image {
    object-fit: cover;
    width: 100%;
    height: 214px;
    max-height: 214px;
    border-radius: 8px;

    @include on-mobile {
      .image-carousel__images {
        max-height: 182px;
      }
    }
  }
}
