@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.not-found-page {
  #bg-line {
    height: $header-line-height-desktop;
  }

  #page {
    height: 100vh;
  }

  &__info {
    flex: 1;
    margin: 0 20px;

    img {
      margin-bottom: 32px;
    }

    h3 {
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 24px;
      text-align: center;
      color: $color-grey-90;
    }
  }
}
