@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-notification {
  border-radius: 12px;
  padding: 16px;

  .font-icon {
    margin-right: 12px;
  }

  &.success {
    background: $color-notification-success-bg;
    border: 1px solid $color-notification-success-border;

    .font-icon {
      color: $color-notification-success-icon;
    }
  }

  &.error {
    background: $color-notification-error-bg;
    border: 1px solid $color-notification-error-border;

    .font-icon {
      color: $color-notification-error-icon;
    }
  }

  &.warning {
    background: $color-notification-warning-bg;
    border: 1px solid $color-notification-warning-border;

    .font-icon {
      color: $color-notification-warning-icon;
    }
  }

  &.disabled {
    background: $color-notification-disabled-bg;
    border: 1px solid $color-notification-disabled-border;

    .font-icon {
      color: $color-notification-disabled-icon;
    }
  }

  &.info {
    background: $color-notification-info-bg;
    border: 1px solid $color-notification-info-border;

    .font-icon {
      color: $color-notification-info-icon;
    }
  }

  & .font-icon-cross {
    margin-left: auto;
    cursor: pointer;
  }

  &__content {
    font-family: Inter, sans-serif;

    span,
    div,
    b {
      font-family: inherit;
    }
  }
}
