@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$list-width: 300px;

.locale-list {
  .ui-dropdown__label {
    background: transparent;
    color: $white;
    outline: none;
  }

  .label__placeholder {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    display: flex;
    align-items: center;

    .item {
      &__image-wrapper {
        width: 38px;
        display: flex;
      }

      &__name {
        flex: 1;
      }
    }
  }
}

.locale-list__image {
  width: 27px;
}
