@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$weekly-list-cell-height: 80px;
$weekly-list-header-height: 55px;

.weekly-list {
  &__cell {
    place-items: center center;

    @include on-desktop {
      box-shadow:
        1px 0 0 0 $color-weekly-list-border,
        0 1px 0 0 $color-weekly-list-border,
        1px 0 0 0 $color-weekly-list-border inset,
        0 1px 0 0 $color-weekly-list-border inset;
      height: $weekly-list-cell-height;
      min-height: $weekly-list-cell-height;

      &--header {
        height: $weekly-list-header-height;
        min-height: $weekly-list-header-height;
      }

      &--no-borders {
        box-shadow: none;
      }
    }
  }
}
