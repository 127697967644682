@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.back-button {
  color: $color-background-text;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-family: $font-secondary;
  position: relative;
  z-index: 1;
  margin-bottom: 16px;

  @include on-mobile {
    display: none;
  }

  &__title {
    margin-left: 8px;
  }
}
