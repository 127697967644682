@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-switch {
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: $color-grey-30;
  overflow: hidden;

  &__thumb {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $white;
    transition: left 0.15s ease;
    z-index: 1;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }

  &__track {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: $color-primary-70;
    transition: opacity 0.15s ease;
  }

  &--checked {
    .ui-switch {
      &__thumb {
        left: 22px;
      }

      &__track {
        opacity: 1;
      }
    }
  }
}
