@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.checkout {
  &__accommodation {
    @include font(16px, 24px, 600);

    color: $color-extras-accommodation-select-fg;
    cursor: pointer;

    * {
      font-family: $font-secondary;
    }

    h4 {
      margin: 0;
    }

    &-warning {
      @include font(14px, 20px, 400);

      padding: 16px;
      border-radius: 12px;
      background: linear-gradient(0deg, rgb(255 255 255 / 90%), rgb(255 255 255 / 90%)), #bf0f24;

      span {
        font-family: $font-secondary;
      }

      :last-child {
        margin-left: auto;
        color: $color-extras-accommodation-warning-close;
        cursor: pointer;
      }

      :first-child {
        color: $color-extras-accommodation-warning;
        margin-right: 14px;
      }
    }

    &-description {
      @include font(16px, 24px, 600);

      color: $black;
      font-family: $font-secondary;
    }

    &--selected {
      display: flex;
      align-items: center;
      padding: 4px 12px;
      border-radius: 4px;
      background: $color-extras-ancillary-selected-bg;
      border: 1px solid $color-extras-ancillary-selected-border;

      * {
        color: $color-extras-ancillary-selected-fg;
      }

      span {
        @include font(14px, 18px, 600);

        font-family: $font-secondary;
      }

      .font-icon {
        font-weight: 500;
        transform: translateY(2px);
      }
    }

    &-group {
      @include font(16px, 24px, 400);

      color: $black;
    }

    &-subtitle {
      @include font(14px, 20px, 400);

      color: $color-extras-ancillaries-description;
      font-family: $font-secondary;
    }
  }
}
