@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.passenger-fieldset {
  &__section {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @include on-desktop {
      grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    }
  }
}
