@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-slider {
  width: 100%;
  position: relative;

  &__rail {
    width: 100%;
    background-color: $color-grey-20;
    height: 6px;
    border-radius: 8px;
  }

  &__track {
    background-color: $color-secondary-70;
    height: 6px;
    border-radius: 8px;
    position: absolute;
    top: 0;
  }

  &__thumb {
    top: 3px;
    box-sizing: content-box;
    position: absolute;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
    background-color: $color-secondary-70;
    border: 4px solid white;
    border-radius: 50%;
    box-shadow: 0 4px 16px 0 #0b082729;
    cursor: pointer;
    transition: border-width 0.1s;

    &:hover {
      border-width: 6px;
    }

    &:focus {
      border-width: 6px;
      box-shadow: 0 2px 16px 0 #0b08274c;
      outline: none;
    }
  }
}
