@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@mixin section-styles() {
  &.page-section--elevated .page-section__content,
  &.page-section--flat {
    @content;
  }
}

.page-section {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  @include on-tablet {
    padding: 0 20px;
  }

  &--header {
    @include section-styles {
      @include background-header;
    }
  }

  &--default {
    @include section-styles {
      @include background-default;
    }
  }

  &--white {
    @include section-styles {
      @include background-white;
    }
  }

  &--transparent {
    @include section-styles {
      background-color: transparent;
    }
  }

  &--image-background {
    color: $white;
  }

  &--vertical-center {
    .page-section__content {
      justify-content: center;
      display: flex;
    }
  }

  &__content {
    flex-grow: 1;
    max-width: $page-max-width;
    padding: 60px 20px;

    @include on-desktop {
      padding: 80px 0;
    }
  }
}
