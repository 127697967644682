@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.page-footer__brand {
  &-social {
    display: block;
    padding: 12px;
    text-decoration: none;
    color: $color-secondary-70;
    border: 1px solid $color-grey-20;
    border-radius: 50%;
  }

  .partner-logo img {
    @include on-mobile {
      max-height: 32px;
    }
  }
}
