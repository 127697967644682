@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.footer-menu {
  &__link {
    color: $black;
    text-decoration: none;
    font-family: $font-secondary;

    &:hover {
      text-decoration: underline;
      color: $color-grey-60;
    }
  }

  &__icon {
    color: $color-secondary-70;
    border-radius: 50%;
    border: 1px solid $color-secondary-70;
    padding: 8px;
  }

  @include on-mobile {
    flex-wrap: wrap;

    &__column.cell {
      flex-grow: 1;
      max-width: unset;
    }
  }
}
