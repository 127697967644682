@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.whatsapp-button {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 7vh;
  height: 7vh;
  bottom: 5vh;
  right: 5vh;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;
  pointer-events: auto;

  @include on-mobile {
    bottom: 32px;
    left: 12px;
  }
}
