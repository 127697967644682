@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.extras {
  position: relative;

  &__section {
    margin-bottom: 24px;
    border-bottom: 1px solid $color-extras-section-border;
    padding-bottom: 24px;

    &:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &-details {
      border-bottom: 1px solid $color-extras-section-border;
      padding: 16px 0;

      &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      h4 {
        @include font(16px, 24px, 600);

        font-family: $font-secondary;
      }
    }
  }

  &__remove {
    @include font(16px, 24px, 600);

    font-family: $font-secondary;
    color: $color-extras-accommodation-select-fg;
    cursor: pointer;
  }

  .loader {
    position: absolute;
    background: $color-booked-bg;
  }
}
