@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$deck-min-length: 922px;
$grid-width: 300px;

$padding-back: 30px;

.seat-selection__deck-wrapper {
  --deck-rotation: 0deg;
  --padding-front: 24px;
  --side-paddings: 12px;
  --rows: 15;
  --columns: 20;
  --deck-width: calc(302px + var(--side-paddings) * 2);

  $cell-size: calc($grid-width / var(--rows));
  $deck-length: calc($cell-size * var(--columns) + var(--padding-front) + $padding-back);

  overflow: visible;
  position: relative;
  background: $color-seat-selection-bg;
  border: 1px solid $color-seat-selection-border;
  box-shadow: 0 0 12px rgb(11 8 39 / 8%);
  border-radius: 40px;

  .seat-selection__deck {
    position: relative;
    display: grid;
    transform-origin: 150px 150px;
    rotate: var(--deck-rotation);
    grid-auto-columns: minmax($cell-size, 0);
    grid-auto-rows: minmax($cell-size, 0);
  }

  &--dense {
    --side-paddings: 0px;

    & .seat-selection__deck {
      width: max-content;
      aspect-ratio: 1 / 1;
    }
  }

  &--up {
    --deck-rotation: 90deg;

    width: var(--deck-width);
    height: max-content;
    min-height: $deck-length;
    padding: var(--padding-front) var(--side-paddings) $padding-back;

    .deck-counter-rotate {
      rotate: calc(270deg);
    }

    .deck-item {
      &--facingForward .deck-counter-rotate {
        rotate: calc(270deg);
      }

      &--facingRight .deck-counter-rotate {
        rotate: calc(180deg);
      }

      &--facingBackward .deck-counter-rotate {
        rotate: calc(90deg);
      }

      &--facingLeft .deck-counter-rotate {
        rotate: calc(0deg);
      }
    }
  }

  &--right {
    height: var(--deck-width);
    width: max-content;
    min-width: $deck-length;
    padding: var(--side-paddings) var(--padding-front) var(--side-paddings) $padding-back;

    .deck-item {
      &--facingForward .deck-counter-rotate {
        rotate: calc(0deg);
      }

      &--facingRight .deck-counter-rotate {
        rotate: calc(270deg);
      }

      &--facingBackward .deck-counter-rotate {
        rotate: calc(180deg);
      }

      &--facingLeft .deck-counter-rotate {
        rotate: calc(90deg);
      }
    }
  }

  &--head {
    --padding-front: 140px;

    border-radius: 140px 140px 40px 40px;
  }

  .seat-selection__extra-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .deck-item {
    &--facingForward {
      rotate: 0deg;
    }

    &--facingRight {
      rotate: 90deg;
    }

    &--facingBackward {
      rotate: 180deg;
    }

    &--facingLeft {
      rotate: 270deg;
    }
  }

  .seat-selection__lights {
    position: absolute;
    height: 30px;
    width: 3px;
    right: 0;
    transform: translateX(100%);
    background: $color-seat-lights-bg;
    border-radius: 0 20px 20px 0;

    @include on-mobile {
      width: 30px;
      height: 3px;
      top: 0;
      transform: translateY(-100%);
    }

    &--left {
      top: 56px;

      @include on-mobile {
        left: 56px;
        top: 0;
        border-radius: 20px 20px 0 0;
      }
    }

    &--right {
      bottom: 56px;

      @include on-mobile {
        right: 56px;
      }
    }
  }
}
