@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  .seat-selection__deck-wrapper {
    margin: 20px;

    @include on-desktop {
      margin: 40px;
    }
  }
}
