@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.car-selector {
  position: relative;
  overflow: visible;
  padding: 1px;

  @include on-mobile {
    overflow-x: auto;
  }

  &__car-button {
    .ui-button {
      height: 40px;
    }
  }

  .car-selector__scroll-button {
    position: absolute;
    top: 50%;

    .ui-button {
      background-color: white;
      width: 32px;
      height: 32px;
      padding: 5px;
    }

    &--left {
      left: 0;
      transform: translate(-50%, -50%);
    }

    &--right {
      right: 0;
      transform: translate(50%, -50%);
    }
  }
}
