@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.invoice {
  background-color: $white;
  box-shadow: 0 2px 16px rgb(11 8 39 / 8%);

  &:hover {
    box-shadow: 0 2px 16px rgb(11 8 39 / 14%);
  }

  border-radius: 12px;

  .ui-accordion {
    &__header {
      padding: 24px 32px;
    }

    &__content {
      padding: 0 32px 24px;
    }
  }

  &__modal {
    margin-bottom: 74px;

    &-save {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: $z-index-modal;
    }

    &-remove {
      & .ui-button {
        text-align: start;
      }
    }
  }

  &__preview {
    border: none;
    border-radius: 8px;
    outline: 1px solid $color-grey-30;

    span {
      font-family: $font-secondary;
    }

    &-code {
      color: $color-grey-70;
    }

    .font-icon {
      color: $color-secondary-70;
    }
  }
}
