@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.trip-preview {
  @include font(14px, 20px);

  font-family: $font-secondary;
  display: flex;
  flex-direction: column;
  color: $color-background-text;
  padding: 0 20px;

  &__station {
    @include gap(10px);

    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 30px;

    &-info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__date {
    @include font(12px, 16px, 400);

    padding-top: 6px;

    &-range {
      margin: auto;
      transform: translateX(10px);
    }

    .font-icon {
      color: $color-background-text;
      transform: translateY(-100%);
    }
  }

  &__search-form-button {
    color: $color-grey-40;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
  }
}
