@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@import '@components/Seats/DeckFrame/index';

.seat-selection {
  &-service-seat {
    background-color: $color-grey-30;
    margin: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--dark {
      background-color: $color-grey-60;
    }
  }

  &__labeled-seat {
    @extend .deck-counter-rotate;
  }
}
