@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-file-upload {
  text-align: left;

  &__input {
    display: none;
  }

  &__button {
    align-self: start;
    color: $color-secondary-70;
    border: 1px solid $color-grey-30;
    border-radius: 8px;
    background-color: transparent;
    padding: 16px 26px;
    display: flex;
    gap: 8px;
    cursor: pointer;

    &:hover {
      color: $color-secondary-60;
    }

    &:active {
      color: $color-secondary-80;
    }
  }

  &__remove {
    cursor: pointer;
  }

  &__invalid {
    padding-top: 4px !important;
    color: $color-error;
    display: flex;
  }

  &--disabled {
    .ui-file-upload__button {
      cursor: default;
      color: $color-grey-40;
    }
  }

  &__file-name {
    word-break: break-word;
  }
}
