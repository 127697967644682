@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.sidebar-menu {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: $z-index-modal;
  transition: right 200ms ease-in-out;
  overflow: hidden;

  &__controls {
    color: $black;

    .ui-button {
      font-weight: 400;
    }
  }

  &--opened {
    right: 0;
  }

  &--closed {
    right: 100%;
  }

  &__close-button {
    text-align: right;
    padding: 22px 26px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-family: $font-secondary;

    .item {
      &__left {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      &__right {
        @include gap(25px);

        display: flex;

        .right__text {
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  &__root,
  &__page {
    width: 100%;
    height: 100%;
    position: absolute;
    transition:
      right 200ms ease-in-out,
      left 200ms ease-in-out;
  }

  &__root {
    right: 0;

    &--hidden {
      right: 100%;
    }
  }

  &__page {
    left: 0;

    &--hidden {
      left: 100%;
    }
  }
}
