@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.payment-in-progress {
  padding: 32px;

  &__icon {
    height: 62px;
  }

  &__title {
    margin: 0;
  }
}
