@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.form-block-additional {
  &__terms {
    color: $color-outline-text-70;
    text-decoration: underline;
  }

  .ui-checkbox__input {
    align-self: center;
  }
}
