@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.search-page {
  #header {
    padding-left: 0;
    padding-right: 0;
  }

  .partner-logo {
    position: relative;
    z-index: 1;
  }

  #bg-line {
    height: $header-line-search-height-mobile;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    @include on-desktop {
      height: $header-line-search-height-desktop;
    }
  }

  .background-offset {
    height: 184px;
  }

  &__form {
    position: relative;
    z-index: 10;

    .page-section__content {
      padding: 0;

      @include on-mobile {
        padding: 0 20px;
      }
    }
  }

  &__connections {
    @include on-desktop {
      padding: 40px 0 32px;
    }

    @include on-tablet {
      padding: 32px 0 28px;
    }

    @include on-mobile {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}
