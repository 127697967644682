@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.price-hint {
  &__icon {
    width: 32px;
    height: 32px;
    background-color: $color-success-2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-success;
    border-radius: 4px;
  }

  &__amount {
    color: $color-success;
    font-weight: bold;
    font-family: $font-secondary;
  }

  &__highlight {
    text-decoration: underline;

    .price-hint__amount {
      text-decoration: underline;
    }
  }
}
