@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.price {
  &__total {
    @include font(20px, 24px, 600);

    margin-bottom: 4px;
  }

  &__per-person {
    @include font(12px, 16px, 400);

    color: $color-grey-70;
  }

  &__installments {
    @include font(16px, 24px, 400);

    margin-bottom: 8px;
  }

  &__discount {
    [type='button'] {
      @include font(12px, 16px, 600);
    }
  }

  .loader {
    height: 44px;
  }
}
