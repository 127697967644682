@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
/* stylelint-disable length-zero-no-unit */
$order: (
  0: 0px,
  1: 10px,
);

$centered: 10px;

.journey-card {
  &__preview-media {
    margin-left: 8px;
    min-width: 116px;
    height: inherit;

    &-container {
      position: relative;
      width: 100%;
      height: 116px;
      cursor: pointer;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 10;
    }

    @each $index, $gap in $order {
      &-content--#{$index} {
        object-fit: cover;
        position: absolute;
        border-radius: 8px;
        width: 116px;
        height: 116px;
        top: calc(50% + $gap);
        left: $gap;
        transform: translateY(calc(-50% - 5px));
        z-index: 10 - $index;
        border: 0;
      }
    }

    &-content--centered {
      left: $centered;
      transform: translateY(-50%);
    }
  }
}
