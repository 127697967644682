@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
#header {
  padding: 14px 16px;
  color: $color-grey-20;
  width: 100%;
  max-width: $page-max-width;
  z-index: 11;
  position: relative;

  .ui-button {
    font-weight: 400;
  }

  .burger-button {
    color: $color-background-text;
  }

  .ui-dropdown__label .label__placeholder {
    flex-shrink: 0;
    flex-basis: auto;
  }

  @include on-desktop {
    padding-left: 0;
    padding-right: 0;

    .ui-dropdown__label {
      color: $color-background-text;
    }
  }

  .manage-bookings .ui-button {
    font-weight: 400;
  }
}

.track-trip,
.manage-bookings {
  @include on-mobile {
    .ui-button {
      text-align: left;
    }
  }
}

.track-trip-modal {
  position: relative;
  padding: 24px;

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__image {
    width: 64px;
  }

  &__confirm {
    min-width: 260px;
  }
}

.header-menu-items {
  display: flex;
  gap: 24px;
}

.header-menu {
  font-weight: normal;

  @include on-desktop {
    color: $color-background-text;
    font-weight: normal;

    & > * {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateY(2px);
        height: 2px;
        border-radius: 2px;
        width: 0;
        background-color: $color-background-text;
        transition: all 0.2s ease;
      }

      &:hover {
        &::before {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  &.cell {
    flex: 0 0 auto;
  }

  &__link {
    text-decoration: none;
    color: inherit;
  }

  &__nested-items {
    color: $black;
  }

  .ui-accordion {
    .ui-accordion__header {
      padding: 0;
    }

    .ui-accordion__content {
      padding-top: 12px;
    }
  }

  &__group {
    font-weight: normal;

    .font-icon {
      display: block;
    }

    &-tooltip {
      color: $black;
      flex-direction: column;
      max-width: unset;
      max-height: unset;
      padding: 4px;
    }

    .header-menu__group-icon {
      margin-left: 16px;
    }

    &-title {
      font-weight: normal;
    }

    &--upperLevel {
      .header-menu__group-icon {
        margin-left: 8px;
        transition: transform 0.3s ease;
        transform: rotate(90deg);
      }

      &.header-menu__group--opened {
        .header-menu__group-icon {
          transform: rotate(270deg);
        }
      }
    }

    @include on-mobile {
      &.level-0 {
        padding-left: 16px;
      }

      &.level-1 {
        padding-left: 32px;
      }
    }
  }

  &__nested-item {
    & > * {
      display: block;
      text-align: start;
      width: 100%;
      padding: 12px 0;

      @include on-desktop {
        padding: 12px 16px;
        border: 1px solid transparent;

        &:hover {
          background-color: $color-primary-20;
          border-radius: 8px;
          border-color: $color-primary-40;
        }
      }
    }

    @include on-mobile {
      &:last-child > * {
        padding-bottom: 0;
      }
    }
  }
}
