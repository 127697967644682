@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.fare-class {
  display: flex;
  position: relative;
  border: 1px solid $color-fare-class-card-border;
  border-radius: 8px;
  cursor: pointer;
  height: 100%;

  &--selected {
    border-width: 2px;
    border-color: $color-fare-class-card-border-selected;
    background-color: $color-fare-class-card-selected;
  }

  &__card-content {
    flex: 1;
  }

  &__card {
    &--disabled {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-booked-bg;
      z-index: 5;
      border-radius: 12px;
      cursor: initial;

      div {
        @include font(16px, 24px);

        background: $color-card-amount;
        color: $color-card-bg;
        border-radius: 4px;
        padding: 4px 12px;
      }
    }

    &-learn-more {
      width: fit-content;

      span {
        font-family: $font-secondary;
        color: $color-secondary-70;
      }
    }
  }

  &__price {
    @include font(16px, 24px, 600);
  }

  &__title {
    margin-bottom: 0;
  }

  &__icon {
    color: $color-fare-class-card-icon;
  }

  .loader {
    height: 23px;
  }

  & .collection-content {
    height: 200px;
    flex: 1;
  }

  &__container {
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include on-desktop {
      flex-wrap: wrap;
    }
  }

  &__modal {
    * {
      font-family: $font-secondary;
    }

    & .ui-modal__header {
      box-shadow: none;
    }

    & .ui-modal__content {
      padding: 0 20px 20px;
    }
  }
}
