@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.departure-time-info {
  gap: 8px;

  &__timetable {
    @include font(16px, 24px, 600);

    cursor: pointer;

    span {
      font-family: $font-secondary;
      color: $color-secondary-70;
    }
  }

  &__frequency {
    @include font(14px, 20px, 400);

    span {
      font-family: $font-secondary;
    }

    .font-icon {
      color: $color-secondary-70;
    }
  }

  &__modal {
    width: 100%;

    & .ui-modal__content {
      padding: 0 20px 20px;
    }

    & .ui-modal__header {
      box-shadow: none;
    }

    & .ui-tabs__header {
      justify-content: start;
      margin-bottom: 27px;
    }

    &-timetable {
      span {
        @include font(20px, 24px, 600);

        font-family: $font-secondary;
      }

      &-header {
        background: $color-grey-10;
        border-radius: 8px 8px 0 0;

        span {
          @include font(16px, 24px, 600);
        }
      }
    }
  }
}
