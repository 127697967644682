@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.outbound {
  font-family: Inter, sans-serif;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 12px;
  cursor: pointer;
  padding: 16px 20px 12px;
  margin: 0 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;
  max-height: 136px;
  overflow: hidden;

  &--closed {
    animation: outbound-closed 300ms;
    max-height: 0;
    padding: 0;
    margin-bottom: 0;
  }

  &--opened {
    animation: outbound-opened 300ms;
  }

  @include on-desktop {
    box-shadow: $box-shadow-3-bottom;
    padding: 20px 24px;
    max-height: 120px;
  }

  &__header {
    @include gap(16px);

    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #039b24;
    color: $white;
    width: 24px;
    height: 24px;

    span {
      transform: translateX(1px);
    }
  }

  &__title {
    @include font(18px, 24px);

    font-family: Poppins, Inter, sans-serif;
  }

  &__date-range {
    @include font(14px, 20px);

    color: $black;
    margin-bottom: 4px;
  }

  &__description {
    @include font(14px, 20px, 400);

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-grey-70;

    &-station {
      display: flex;
      margin-bottom: 8px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include on-desktop {
          overflow: unset;
        }
      }

      @include on-desktop {
        margin-bottom: 0;
      }
    }

    &-price {
      @include font(16px, 24px);

      color: $black;
      text-align: end;
    }

    .font-icon {
      margin: 0 10px;
    }
  }
}

@keyframes outbound-opened {
  0% {
    max-height: 0;
    padding: 0 20px;
    margin-bottom: 0;
  }

  100% {
    max-height: 136px;
    padding: 16px 20px 12px;
    margin-bottom: 20px;
  }
}

@keyframes outbound-closed {
  0% {
    max-height: 136px;
    padding: 16px 20px 12px;
    margin-bottom: 20px;
  }

  100% {
    max-height: 0;
    padding: 0 20px;
    margin-bottom: 0;
  }
}

@include on-desktop {
  @keyframes outbound-opened {
    0% {
      max-height: 0;
      padding: 0 20px;
      margin-bottom: 0;
    }

    100% {
      max-height: 120px;
      padding: 20px 24px;
      margin-bottom: 20px;
    }
  }

  @keyframes outbound-closed {
    0% {
      max-height: 120px;
      padding: 20px 24px;
      margin-bottom: 20px;
    }

    100% {
      max-height: 0;
      padding: 0 20px;
      margin-bottom: 0;
    }
  }
}
