@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.faq-tabs {
  max-width: 700px;
  align-items: center;

  &__description {
    text-align: center;
  }

  &__content {
    $width: 560px;

    max-width: $width;

    @include on-desktop {
      width: $width;
    }
  }

  &__navigation {
    @include on-desktop {
      padding: 0 30px;
    }

    .ui-tabs {
      &__header {
        div:not(:last-child) {
          padding-right: 0;
        }
      }
    }
  }
}
