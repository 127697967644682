@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.invoice-form {
  @include on-desktop {
    .ui-tabs {
      &__header-wrapper {
        width: auto;
      }

      &__header-label {
        min-width: 134px;
      }
    }
  }
}
