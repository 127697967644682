@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$v-padding: 32px;
$h-padding: 20px;
$v-h-padding-mob: 20px;

.journey-card {
  &__body {
    @include gap(20px);

    display: flex;
    padding: 0 $v-h-padding-mob;

    @include on-desktop {
      @include gap(0);

      padding: 0 $v-padding;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 135px;

      @include on-desktop {
        max-width: 315px;
      }

      &--fares {
        @include on-desktop {
          max-width: 280px;
        }
      }
    }

    &-time {
      @include font(20px, 24px);

      color: $color-departure-time-fg;

      @include on-desktop {
        @include font(24px, 28px);
      }

      &-difference {
        @include font(12px, 16px, 500);

        color: $color-card-time;
        vertical-align: super;
        padding-left: 4px;

        @include on-desktop {
          @include font(14px, 18px, 500);
        }
      }

      &-decoration {
        width: 8px;
        height: 1px;
        background: $color-fare-class-icon-fg;
        border-radius: 6px;

        @include on-desktop {
          width: 75px;
          height: 1px;
        }

        &--fares {
          @include on-desktop {
            width: 50px;
            height: 1px;
          }
        }
      }
    }

    &-duration {
      @include font(12px, 16px, 500);

      display: flex;
      align-items: center;
      color: $color-card-time;
      transform: translateX(8px);

      @include on-desktop {
        @include font(14px, 18px, 500);

        margin: auto;
        transform: translateX(0);
      }

      &-time {
        margin: 0 8px;
      }
    }

    &-location {
      @include font(14px, 20px, 400);

      margin-top: 4px;
      max-width: 220px;
    }

    &-line {
      @include font(12px, 16px, 500);

      margin-left: auto;
      margin-bottom: 12px;
      text-align: center;

      @include on-mobile {
        line-break: anywhere;
      }
    }
  }
}
