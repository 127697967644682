@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-alert {
  padding: 16px;
  width: 100%;
  border-radius: 12px;

  &__close {
    cursor: pointer;
  }

  &.error {
    background-color: rgba($color-error, 0.15);

    .ui-alert__type-icon {
      color: $color-error;
    }
  }
}
