@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  &__header {
    &-stations {
      @include gap(12px);
      @include font(14px, 20px);

      display: flex;
      margin-top: 14px;

      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .font-icon {
        font-weight: 400;
        cursor: pointer;
      }
    }

    font-family: $font-secondary;
  }
}
