@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.submit-section {
  @include on-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    padding: 16px 20px;
    z-index: 10;
    box-shadow: 0 -4px 12px rgb(11 8 39 / 8%);
  }
}
