@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.adyen-checkout__qr-loader__countdown {
  display: none;
}

.adyen-checkout__qr-loader__progress {
  display: none;
}

.adyen-checkout__qr-loader__app-link {
  display: none;
}
