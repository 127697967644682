@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
@import '@components/Seats/DeckFrame/index';

.seat-selection {
  &__seat {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &-surface {
      position: absolute;
      left: 5px;
      top: 1px;
      width: 39px;
      height: 42px;
      border-radius: 6px;
      border-width: 2px;
      border-style: solid;
      border-color: $color-seat-available;

      &-icon {
        @extend .deck-counter-rotate;

        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    &-armrest {
      width: 29px;
      height: 8px;
      border-radius: 4px;
      background: $color-seat-available;
      box-shadow: 0 0 8px 0 #2826381f;
      position: absolute;
      z-index: 1;
      left: 4px;

      &-label {
        @extend .deck-counter-rotate;
      }

      &--left {
        top: 0;
      }

      &--right {
        bottom: 0;
      }

      &--back {
        width: 12px;
        height: 38px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
      }

      .font-icon-star-simple {
        @extend .deck-counter-rotate;

        &.seat-selection__seat-sign {
          font-size: 8px;
          width: 8px;
          height: 8px;
          line-height: 8px;
        }
      }
    }

    &--hide {
      display: none;
    }

    &--paid {
      .seat-selection__seat-surface {
        border-color: $color-seat-paid;
      }

      .seat-selection__seat-armrest {
        background: $color-seat-paid;
      }
    }

    &-discount {
      @extend .deck-counter-rotate;

      font-weight: 600;

      &--disabled {
        color: $color-seat-label-disabled;
      }

      &-index {
        font-size: 8px;
      }
    }

    &--discounted {
      .seat-selection__seat-surface {
        border-color: $color-seat-discounted;
      }

      .seat-selection__seat-armrest {
        background: $color-seat-discounted;
      }
    }

    &--disabled {
      cursor: initial;
      pointer-events: none;

      .seat-selection__seat-surface {
        border-color: $color-seat-disabled;
      }

      .seat-selection__seat-armrest {
        background: $color-seat-disabled;

        &-label {
          color: $color-seat-label-disabled;
        }
      }
    }

    &--selected {
      .seat-selection__seat-surface {
        border-color: $color-seat-selected;
        background-color: $color-seat-selected;
      }

      .seat-selection__seat-armrest {
        background: $color-seat-thing-selected;
        box-shadow: 0 0 8px rgb(40 38 56 / 40%);
      }
    }

    &--disabled,
    &--selected,
    &--paid,
    &--discounted {
      .seat-selection__seat-armrest--back {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        color: #fff;
        font-size: 10px;
      }
    }

    &-wrapper {
      position: relative;
      padding: 8px;
    }

    &-steering-wheel {
      top: 15px;
      left: 15px;
      position: absolute;

      @include on-desktop {
        top: 15px;
        right: 15px;
        left: unset;
      }
    }

    &-label {
      @extend .deck-counter-rotate;
      @include font(13px, 16px, 600);

      position: absolute;
      color: $black;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      font-family: $font-secondary;
      transform-origin: top;

      &--selected {
        font-weight: 600;
      }
    }

    &-tooltip {
      max-width: none;

      .seat-selection__seat-wrapper {
        width: 100%;
        height: 100%;
      }

      .ui-button-wrapper {
        margin-top: 8px;
        overflow: hidden;

        .ui-button {
          width: 120px;
          padding: 8px 0;
          height: auto;
          word-wrap: normal;

          @include on-desktop {
            padding: 8px 24px;
          }
        }
      }

      &-wrapper {
        max-width: none;
        max-height: none;
      }

      * {
        font-family: $font-secondary;
      }
    }

    .font-icon-cross {
      color: $color-seat-icon-disabled;
    }

    .font-icon-cross,
    .font-icon-checkout {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .font-icon-checkout {
      color: $white;
    }

    .font-icon-dollar,
    .font-icon-euro,
    .font-icon-pound {
      @extend .deck-counter-rotate;

      &.seat-selection__seat-sign {
        font-size: 10px;
        width: 10px;
        height: 10px;
        line-height: 10px;
      }

      @include on-desktop {
        transform: translateY(-1px);
      }
    }

    & .font-icon__content {
      width: 100%;
    }
  }
}
