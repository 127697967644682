@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.voucher {
  &__empty {
    color: $color-outline-text-70;
    cursor: pointer;
  }

  &__applied {
    color: $color-grey-80;
  }

  &__apply {
    height: 56px;
    display: flex;
    align-items: center;
  }
}
