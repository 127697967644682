@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.trip-details {
  display: flex;
  gap: 12px;
  align-items: center;

  .icon-container {
    &__icon {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-secondary-20;
      color: $color-secondary-70;
    }
  }

  &__text {
    a {
      text-decoration: none;
      font-weight: 600;
    }
  }
}
