@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.insurance {
  &__passenger-label {
    color: $color-grey-50;
  }

  &__passenger {
    align-items: center;

    @include on-mobile {
      align-items: stretch;
    }
  }

  &__error {
    text-align: start;

    .message__text {
      @include font(14px, 18px, 400);

      color: $color-error;
    }
  }
}
