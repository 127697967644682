@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$max-item-width: 100%;

.carousel {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;

  &__list {
    display: flex;
    height: 100%;

    .list-item {
      box-sizing: border-box;
      max-width: $max-item-width;
      height: 100%;
      width: 100%;
      position: relative;
      border-width: 1px;
      border-style: solid;
      border-color: $color-carousel-border;
      background-color: $color-carousel-bg;
      cursor: pointer;

      @include on-mobile {
        padding-bottom: 1px;
      }

      .loader__spinner {
        height: calc(100% - 10px);
        max-width: 50%;
      }

      .list-item__secondary-text {
        @include font(12px, 16px, 500);

        display: flex;
        justify-content: center;
        color: $color-carousel-fg-secondary-text;
        font-family: $font-secondary;

        @include on-desktop {
          @include font(14px, 16px, 400);
        }
      }

      .list-item__primary-text {
        @include font(14px, 20px, 600);

        color: $color-carousel-fg-secondary-text;
        padding-bottom: 4px;
        font-family: $font-secondary;
      }

      &.list-item--selected {
        background-color: $color-carousel-bg-selected;

        .list-item__secondary-text {
          color: $color-carousel-fg-secondary-text-selected;
        }

        &:hover {
          background-color: $color-carousel-bg-selected-hover;
        }

        .list-item__primary-text {
          color: $color-carousel-fg-primary-text-selected;
        }
      }

      &:hover:not(.list-item--selected, :disabled) {
        border-color: $color-carousel-border-hover;
      }

      &:disabled {
        border-color: $color-carousel-border-disabled;
        cursor: initial;

        .list-item__primary-text {
          color: $color-carousel-fg-primary-text-disabled;
        }

        .list-item__secondary-text {
          color: $color-carousel-fg-primary-text-disabled;
        }
      }

      &:first-child {
        border-radius: 12px 0 0 12px;
      }

      &:last-child {
        border-radius: 0 12px 12px 0;
      }
    }
  }
}
