@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$padding-left: 16px;
$padding-left-icon: 32px;
$input-border-radius: 8px;

.credit-card-form {
  padding-left: 32px;

  @include on-mobile {
    padding-left: 0;
  }

  .adyen-checkout {
    color: red;

    &__input-wrapper {
      display: flex;
      position: static;
    }

    &__input {
      height: 56px;
      top: 0;
      left: 0;
      padding: 24px 16px 8px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      border: none;
      border-radius: $input-border-radius;
      outline: 1px solid $color-input-outline;
      overflow: hidden;

      &--error {
        outline: 2px solid $color-input-error;
      }

      &:hover {
        outline: 1px solid $color-input-focus;
      }

      &--focus,
      &--focus:hover {
        outline: 2px solid $color-input-focus;
      }
    }

    &-input__inline-validation--valid {
      display: none;
    }

    &-input__inline-validation--invalid {
      position: absolute;
      display: flex;
      left: 0;
      bottom: 4px;
      top: unset;
      transform: none;
      width: 16px;
      height: 16px;
      padding: 1px;
    }

    &-error-panel__wrapper {
      display: none;
    }

    &__field {
      position: relative;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      color: $color-input-fg;
      transition: outline-color 150ms;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      flex-shrink: 0;
    }

    &__label__text {
      position: absolute;
      user-select: none;
      pointer-events: none;
      left: $padding-left;
      top: 8px;
      color: $color-input-label-fg;
      z-index: 10;
      transition:
        transform 150ms ease-out,
        font-size 150ms ease-out;
      font-size: 12px;
    }

    &__card {
      &__brands {
        display: none;
      }

      &__form {
        display: flex;
        flex-direction: column;
      }

      &__exp-cvc {
        display: flex;

        @include on-mobile {
          flex-wrap: wrap;
        }

        .adyen-checkout__field {
          flex-basis: calc(50% - 8px);
        }
      }

      &__cvc__hint__wrapper,
      &__cardNumber__brandIcon {
        display: none;
      }
    }

    &__field__exp-date_hint_wrapper {
      display: none;
    }

    &__error-text {
      display: inline;
      font-size: 12px;
      font-weight: 400;
      color: $color-input-error;
      padding-top: 4px;
      padding-left: 20px;
    }

    &__iframe {
      width: 100%;
    }

    &__threeds2 {
      &__challenge {
        width: 100%;
      }
    }
  }
}
