@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.weekly-list {
  &__item {
    .font-icon {
      cursor: pointer;
    }

    & .ui-dropdown__label {
      @include font(16px, 24px, 600);

      outline: none;
      background: transparent;
      padding: 0;
      color: $color-weekly-list-dropdown-label;
      text-align: center;

      & .label__placeholder {
        margin-right: 10px;

        @include on-desktop {
          margin-right: 0;
        }
      }

      & .placeholder__value {
        color: $black;
      }
    }

    & .ui-tooltip {
      justify-self: end;

      @include on-desktop {
        justify-self: center;
      }
    }
  }

  &__date {
    @include font(16px, 24px, 500);

    grid-area: date;
    justify-self: start;

    @include on-desktop {
      grid-area: unset;
      justify-self: unset;
    }
  }

  &__copy {
    @include font(12px, 16px, 500);

    grid-area: copy;

    @include on-desktop {
      grid-area: unset;
    }

    & .font-icon-copy {
      color: $color-weekly-list-copy;
    }

    &--disabled {
      pointer-events: none;

      .font-icon-copy {
        color: $color-weekly-list-copy-disabled;
      }
    }
  }

  &__popper {
    @include scrollbar(12px, 4px);

    border-radius: 12px;
  }

  &__departure-time {
    position: relative;
    justify-self: start;
    grid-area: departure-time;

    @include on-desktop {
      grid-area: unset;
      justify-self: unset;
    }
  }

  &__return-time {
    @extend .weekly-list__departure-time;

    grid-area: return-time;

    @include on-desktop {
      grid-area: unset;
      justify-self: unset;
    }
  }
}
