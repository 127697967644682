@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.submit-modal {
  padding: 24px 32px;
  text-align: center;

  .ui-button-wrapper {
    min-width: 248px;
  }

  &__icon {
    display: flex;
    width: 40px;
    height: 40px;
  }
}
