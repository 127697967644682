@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.search-section {
  .search-section__title-wrapper {
    align-items: center;
    justify-content: center;
    color: $color-background-text;

    a,
    .search-section__title,
    .search-section__subtitle {
      margin: 0;
    }

    .search-section__subtitle {
      @include on-mobile {
        display: none;
      }
    }

    .search-section__title {
      padding-top: 64px;
    }

    @include on-desktop {
      align-items: start;
      padding-right: 32px;
    }
  }

  &__form {
    width: 100%;
    padding: 24px 32px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: $box-shadow-1-bottom;

    @include on-mobile {
      padding: map-get($gap-values, 4);
    }

    &--column {
      width: 400px;

      @include on-mobile {
        width: 100%;
      }
    }
  }
}
