@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.collection {
  &-content {
    position: relative;
    cursor: pointer;

    img,
    video,
    iframe {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      border: 0;
      object-fit: cover;
    }

    &-overlay {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 8px;
      width: 56px;
      height: 56px;
      background-color: rgb(40 38 56 / 30%);
      z-index: 10;
    }

    &-count {
      @include font(16px, 24px, 600);

      font-family: $font-secondary;
      color: $white;
    }
  }

  &-modal {
    @include on-desktop {
      width: 60%;
    }

    & .ui-modal__content {
      padding: 20px;
      overflow-x: hidden;
    }

    & .ui-modal__header {
      box-shadow: none;
    }
  }
}
