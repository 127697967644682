@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.page-footer__help-modal {
  .ui-modal {
    &__header {
      box-shadow: none;
      padding: 24px 20px 10px;

      & > div {
        justify-content: center;
        position: relative;

        div:first-child {
          display: flex;
          justify-content: center;
        }
      }
    }

    &__close-button {
      position: absolute;
      right: 0;
    }

    &__content > div {
      padding-top: 0;
    }
  }

  .help-modal__description {
    text-align: center;
    white-space: pre-line;
  }

  &-contact {
    .ui-modal {
      &__header {
        box-shadow: none;
      }

      &__content > div {
        padding-top: 0;
      }
    }
  }
}
