@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.connection-filters {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-connection-filters-border;
  box-sizing: border-box;
  border-radius: 12px;
  width: 300px;
  background: $white;

  @include media(on-mobile, on-tablet) {
    border: none;
    width: 100%;
  }

  .ui-icon-text {
    &--alternative {
      .font-icon {
        color: $color-connection-filters-icon-text-icon-alternative;
      }
    }
  }

  .ui-checkbox {
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    input {
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section {
    padding: 24px 32px;
    line-height: 24px;

    @include media(on-mobile, on-tablet) {
      padding: 24px;
    }

    &:first-child {
      @include media(on-mobile, on-tablet) {
        padding-top: 8px;
      }
    }

    &:last-child {
      @include media(on-mobile, on-tablet) {
        padding-bottom: 8px;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 18px;
        color: $color-connection-filters-section-title;
        padding-bottom: 16px;
      }

      .font-icon {
        color: $color-connection-filters-collapse-icon;
      }
    }

    &-content {
      .ui-button {
        height: auto;
        padding: 0;

        .font-icon {
          color: unset;
        }
      }

      .font-icon {
        &-map-pin {
          color: $color-connection-filters-collapse-icon;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  &__section:not(:last-child) {
    border-bottom: 1px solid $color-connection-filters-border;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap-reverse;
    padding: 12px 20px;
  }
}
