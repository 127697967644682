@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$color-route-breakdown-icon-fg: #c2c1d2;

.rb-item {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  gap: 8px;
  position: relative;

  &__left {
    @include font(16px, 24px, 400);

    width: 44px;
    min-width: 44px;
  }

  &__middle {
    width: 20px;
    min-width: 20px;
    color: $color-route-breakdown-icon-fg;
    z-index: 1;
  }

  &__icon {
    background: $white;
    padding: 2px 0;
  }

  &__line {
    background-color: $color-route-breakdown-icon-fg;
    width: 1px;
    height: 100%;
    position: absolute;
    left: calc(44px + 8px + 20px / 2);
    top: 0;
  }

  &:first-child {
    .rb-item__line {
      height: calc(100% - 20px);
      top: 20px;
    }
  }

  &:last-child {
    .rb-item__line {
      height: 10px;
    }
  }

  &--duration .rb-item__right {
    @include font(12px, 16px, 500);

    color: $color-card-amount;
    position: relative;
    border: 1px solid $color-journey-card-icon-fg;
    border-radius: 19px;
    background: $white;
    padding: 4px 8px;
    left: -28px;
    z-index: 1;
  }
}

.route-breakdown--hide-left {
  .rb-item {
    &__left {
      display: none;
    }

    &__line {
      left: calc(20px / 2);
    }
  }
}
