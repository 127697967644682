$color-notification-success-bg: $color-primary-10;
$color-notification-success-border: $color-primary-30;
$color-notification-success-icon: $color-primary-70;
$color-notification-error-bg: #fef3f3;
$color-notification-error-border: #fccfcf;
$color-notification-error-icon: $color-error;
$color-notification-warning-bg: linear-gradient(0deg, rgb(255 255 255 / 95%) 0%, rgb(255 255 255 / 95%) 100%), #e7591c;
$color-notification-warning-border: $color-warning;
$color-notification-warning-icon: $color-warning;
$color-notification-disabled-bg: $color-grey-10;
$color-notification-disabled-border: $color-grey-30;
$color-notification-disabled-icon: $color-grey-80;
$color-notification-info-bg: $color-secondary-10;
$color-notification-info-border: $color-secondary-30;
$color-notification-info-icon: $color-secondary-70;
