@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.new-connections-modal {
  & .ui-modal {
    &__header {
      box-shadow: none;
      padding-bottom: 0;
      text-align: center;

      .font-icon-cross {
        display: none;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
    }
  }

  &--empty {
    & .ui-modal__footer {
      box-shadow: none;
    }
  }
}
