@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.fare-class {
  &__navigate {
    position: absolute;
    z-index: 15;
    cursor: pointer;
    border-radius: 50%;
    transform: translateY(-50%);

    &--right {
      top: 50%;
      right: 0;
    }

    &--left {
      top: 50%;
      left: -15px;
    }

    .font-icon {
      color: $color-fare-class-navigation-icon;
      padding: 6px;
      border: 1px solid $color-fare-class-navigation-border;
      border-radius: 50%;
      background: $white;
    }
  }
}
