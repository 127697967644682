@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.ui-checkbox-group {
  &.row {
    @include gap(24px);
  }

  &.column {
    @include gap(20px, true);
  }
}
