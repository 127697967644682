@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.payment-label {
  &__icon {
    max-height: 32px;

    &.amex {
      padding: 4px 0;
    }
  }

  &__brands {
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
    overflow: auto;
    max-width: 90%;
    grid-template-columns: minmax(62px, auto);

    &::-webkit-scrollbar {
      height: 12px;
    }
  }
}
