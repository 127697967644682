@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-list {
  &__seats-loader {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      background: $color-grey-100;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 15;
    }

    &-wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $white;
      padding: 24px;
      border-radius: 8px;
      z-index: 20;

      &--loading {
        width: auto;
      }

      &--error {
        width: 90%;

        @include on-desktop {
          width: 45%;
        }
      }
    }

    &-header {
      * {
        margin-left: auto;
      }

      span {
        cursor: pointer;
      }
    }

    &-body {
      span {
        font-family: $font-secondary;
      }

      h3 {
        text-align: center;
      }
    }

    &-footer {
      div {
        flex: 1;
        min-width: 175px;

        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;
        }

        @include on-desktop {
          flex-basis: 30%;
          max-width: 30%;
          min-width: 200px;

          &:first-child {
            order: 1;
          }

          &:last-child {
            order: 2;
          }
        }
      }
    }
  }
}
