@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.payment-error {
  padding: 32px;

  &__content {
    overflow: hidden;
  }

  &__icon {
    height: 62px;
  }

  &__close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    display: flex;
  }

  &__title {
    margin: 0;
  }
}
