@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.trip-info {
  font-family: $font-secondary;

  &__wrapper {
    flex-basis: auto;
    margin-top: 20px;

    @include on-desktop {
      flex-basis: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__carrier {
    display: flex;
    flex: 1;
    margin-top: 24px;
    margin-left: auto;

    @include on-desktop {
      margin-top: 0;
    }
  }

  &__fee {
    span {
      font-family: $font-secondary;
      color: $font-secondary;
    }

    .font-icon {
      color: $color-secondary-70;
    }
  }
}
