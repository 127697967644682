@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.checkout-form {
  @include on-mobile {
    padding-bottom: 20px;
  }

  &__message {
    background-color: $color-yellow-5;
    color: $color-yellow-10;
    width: 100%;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__trip-details {
    @include on-desktop {
      position: sticky;
    }
  }
}
