@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.train-seats-car {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;

  &__seats-left {
    color: $color-grey-80;
    font-family: $font-secondary;

    @include font(14px, 20px);
  }

  @include on-desktop {
    padding: 16px 24px;
    border: 1px solid #dedde3;
  }
}
