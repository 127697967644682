@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.fare-conditions-info {
  color: $color-secondary-70;
  cursor: pointer;
  margin: 0 4px;
  align-items: center;
  pointer-events: auto;
}

.fare-conditions__description {
  white-space: pre-wrap;
}

.journey-card {
  &__footer {
    &-details {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      pointer-events: auto;

      &--transfers {
        background: $color-journey-card-transfers-bg;
        border: 1px solid $color-journey-card-transfers-border;
        border-radius: 4px;
        padding: 4px 12px;
        white-space: nowrap;
      }

      &-wrapper {
        @include gap(15px);

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin: 20px 0;

        &--fares {
          @include on-mobile {
            margin: 12px 0;
          }
        }

        @include on-desktop {
          @include gap(30px);

          padding: 0 32px;
          justify-content: start;
        }
      }

      &-title {
        @include font(14px, 18px, 500);
      }

      .font-icon {
        color: $color-card-time;
        margin-left: 8px;
      }
    }

    &-amenities {
      @include gap(8px);

      display: flex;
      align-items: center;
      color: $color-amenities-icon-bg;

      &-description {
        @include font(14px, 20px, 400);

        color: $black;
      }

      &-count {
        @include font(12px, 16px, 400);

        cursor: pointer;

        @include on-desktop {
          @include font(14px, 18px, 500);
        }
      }

      &-icon {
        color: $color-detailed-fare-icon;
        transform: translateY(-2px);
        cursor: pointer;
        margin-left: 4px;
        display: block;
      }
    }

    &-features {
      @include gap(4px);

      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: min-content;
      align-items: center;
      padding: 0 12px;
      width: 100%;

      @include on-mobile {
        grid-template-columns: 1fr min-content;
        justify-items: start;
        padding-top: 9px;
        padding-bottom: 14px;
      }

      @include on-desktop {
        @include gap(20px);

        padding: 0 32px 0 8px;
        justify-content: end;
      }

      &-tickets-left {
        color: $color-journey-card-tickets-left;

        @include on-mobile {
          @include font(12px, 16px, 500);

          grid-column: 1 / span 2;
          margin-bottom: 8px;
        }
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 0.5px dashed $color-divider;

        @include on-desktop {
          border-top: none;
          width: 100%;
        }

        @include on-mobile {
          align-items: end;
        }
      }

      &-title {
        @include font(14px, 20px, 500);

        color: $black;
      }

      &-info {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        background: $color-fare-class-bg;
        border: 1px solid $color-border-fare-class;
        border-radius: 4px;
        cursor: pointer;

        .font-icon {
          color: $color-fare-class-icon-fg;
          padding-right: 7px;
        }
      }
    }

    &-passengers {
      @include font(12px, 16px, 500);

      @include on-desktop {
        @include font(14px, 20px, 500);
      }

      transform: translateY(2px);
      cursor: pointer;

      .font-icon {
        color: $color-journey-card-icon-fg;
      }
    }

    &-price {
      white-space: nowrap;

      &-total {
        @include font(16px, 20px);

        font-family: $font-secondary;

        @include on-desktop {
          @include font(20px, 24px);
        }
      }

      &-from {
        @include font(14px, 18px, 400);
      }

      .ui-button {
        text-align: end;
        height: auto;
        padding: 16px 20px;
        border-radius: 0 0 12px;
        transform: translateY(-1px);
        pointer-events: auto;

        @include on-desktop {
          padding: 22px 32px;
          border-radius: 12px 0;
          transform: translateY(0);
        }
      }
    }
  }
}
