@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.journey-list {
  &__sort {
    @include gap(8px);

    display: flex;
    align-items: center;

    &-title {
      @include font(16px, 24px, 400);

      color: $color-journey-list-sort-title;
      max-width: 75px;
      white-space: nowrap;
      margin-right: 8px;
    }

    &-value {
      @include font(16px, 24px);

      color: $black;
      cursor: pointer;
      padding-right: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-dropdown {
      width: auto;

      .ui-dropdown__label {
        @include font(16px, 24px);

        outline: none;
        padding: 0;
        height: auto;
        background: transparent;

        .font-icon {
          color: $color-journey-list-icon;
          transform: translateY(-2px);
          padding-left: 12px;
        }
      }
    }

    &-modal {
      &-content {
        @include font(16px, 24px, 400);

        display: flex;
        justify-content: space-between;
        padding: 20px 28px 20px 20px;

        &--active {
          font-weight: 600;
        }
      }

      .ui-modal__header {
        box-shadow: none;
      }
    }
  }
}
