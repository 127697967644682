@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection-table {
  padding: 8px;
  display: flex;

  &__deck {
    width: 100%;
    height: 100%;
    background-color: $color-grey-30;
    border-radius: 8px;
    position: relative;
  }

  &__link {
    background-color: $color-grey-30;
    position: absolute;

    &--up {
      width: 100%;
      height: 50%;
      top: -12px;
    }

    &--down {
      width: 100%;
      height: 50%;
      bottom: -12px;
    }

    &--right {
      width: 50%;
      height: 100%;
      right: -12px;
    }

    &--left {
      width: 50%;
      height: 100%;
      left: -12px;
    }
  }
}
