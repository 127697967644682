@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.countries-dropdown {
  position: static;
}

.phone {
  position: relative;
  text-align: start;

  &__dropdown {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    padding: 0 16px;
  }

  &__countries {
    border-radius: 8px;
    box-shadow: $box-shadow-2-bottom;
    position: absolute;
    z-index: $z-index-dropdowns;
    top: calc(100% + 2px);
    width: 100%;
    background-color: $color-dropdown-list-bg;
  }

  &__label {
    @include font(12px, 16px, 500);

    color: $color-dropdown-label-fg;
  }

  &__option {
    padding: 8px 16px;
    cursor: pointer;
  }

  .ui-input {
    padding-left: 120px;
  }
}
