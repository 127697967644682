@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.page-footer {
  background-color: #fff;
  width: 100%;
  padding: 0 20px;

  &__content {
    max-width: $page-max-width;
    padding-bottom: 72px;
    padding-top: 36px;

    .row.powered-by-distribusion {
      padding-top: 0;

      @include on-mobile {
        padding-top: 20px;
        justify-content: center;
      }
    }

    @include on-mobile {
      padding-bottom: 36px;
    }
  }

  &__brand-container.cell {
    width: 320px;
    flex-basis: 320px;

    @include on-mobile {
      flex-basis: auto;
      padding-bottom: 0;
    }
  }
}
