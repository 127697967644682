@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.search-bar {
  padding: 20px;
  background-color: $white;
  border-radius: 12px;
  width: 100%;

  @include on-desktop {
    box-shadow: $box-shadow-3-bottom;
    padding: 24px 32px;
    width: auto;
  }
}
