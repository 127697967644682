@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.custom-blocks {
  overflow: hidden;

  .page-section__content.custom-blocks__item,
  &__item {
    &.paper {
      padding: map-get($gap-values, 7) map-get($gap-values, 4);

      @include on-desktop {
        padding: map-get($gap-values, 11);
      }
    }
  }

  &:has(.custom-blocks__item--omit-offset) {
    .background-offset {
      display: none;
    }
  }
}
