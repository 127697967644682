@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.weekly-connections {
  display: grid;
  grid-auto-flow: row;
  padding-bottom: 15px;

  * {
    font-family: $font-secondary;
  }

  @include on-desktop {
    grid-auto-flow: column;
    box-shadow: none;
    overflow: auto;
    padding-right: 1px;
    padding-top: 5px;
  }

  &__date {
    @include font(16px, 24px, 500);

    grid-area: date;

    @include on-desktop {
      grid-area: unset;
    }
  }

  &__departure {
    @include font(16px, 24px, 600);

    grid-area: departure-time;

    @include on-desktop {
      grid-area: unset;
    }
  }

  &__arival {
    @include font(16px, 24px, 600);

    grid-area: return-time;

    @include on-desktop {
      grid-area: unset;
    }
  }
}
