@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background: $color-primary-bg;
}

#bg-line {
  background-color: var(--background);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $header-line-height-mobile;

  @include on-desktop {
    height: $header-line-height-desktop;

    &.dense {
      height: $header-line-height-desktop-dense;
    }
  }
}

#landing-bg-line {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--background);
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @include on-desktop {
    height: 495px;
  }
}

#bg-line,
.background,
#landing-bg-line {
  $header-offset: 84px;
  $banner-height: 56px;

  &.banner {
    top: $banner-height;
  }

  &.banner.header-offset {
    top: $banner-height + $header-offset;
  }

  &.header-offset {
    top: $header-offset;

    &::before {
      content: '';
      top: -$header-offset;
      height: $header-offset;
      width: 100%;
      position: absolute;
      background-color: var(--background);
    }

    @include on-mobile {
      top: 0;

      &::before {
        display: none;
      }
    }
  }
}

#page {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  @include scrollbar(12px, 4px);
}

.page-block {
  max-width: $page-max-width;

  &--header-color {
    background-color: var(--background);
  }
}

hr {
  border: none;
  border-top: 1px solid $color-grey-20;
  margin: 20px 0;
}

b {
  font-weight: bolder;
}
