@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$list-width: 300px;

.currency-list {
  .ui-dropdown__label {
    background: transparent;
    color: $white;
    outline: none;
  }

  .label__placeholder {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    .item {
      &__code {
        width: 38px;
        display: inline-block;
        font-weight: bold;
      }

      &__name {
        display: inline-block;
        margin-left: 40px;
      }
    }

    &--selected {
      background: none;
      font-weight: 600;
    }

    &--separated {
      border-bottom: 1px solid $color-dropdown-currency-border;
    }
  }
}
