@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$gap: 65px;

.journey-card {
  &__modal {
    border-radius: 24px 24px 0 0;

    &.ui-modal {
      height: calc(100% - $journey-list-mobile-gap + $gap);
    }

    .ui-modal__header {
      box-shadow: $box-shadow-3-bottom;
      z-index: 1;
    }

    .ui-modal__footer {
      margin-top: auto;
    }

    &--media {
      .ui-modal__content {
        overflow: hidden auto;
      }
    }

    &-duration {
      @include font(12px, 16px, 500);

      color: $color-card-time;
      font-family: $font-secondary;
    }

    &-button {
      padding: 20px 24px;
      background: $color-card-bg;
      box-shadow: 0 -4px 12px rgb(11 8 39 / 8%);
      margin-top: auto;

      * {
        font-family: $font-secondary;
      }

      &-title {
        @include font(16px, 24px);
      }

      &-price {
        @include font(20px, 24px, 500);
      }

      &--fares {
        padding: 0;
        max-height: 195px;
        overflow: auto;
      }
    }
  }
}
