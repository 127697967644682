@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.popular-direction-header {
  height: 221px;
  overflow: hidden;
  position: relative;

  &::after {
    background: linear-gradient(180deg, rgba(16 13 49 / 0%) 0%, #0d0d0f 100%);
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 0;
  }

  &__image {
    border-radius: 12px 12px 0 0;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__title {
    @include font(16px, 24px, 600);

    align-items: center;
    border-radius: 12px 12px 0 0;
    bottom: 0;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    padding: 12px;
    position: absolute;
    width: 100%;
    column-gap: 12px;
    z-index: 2;

    span {
      font-family: Inter, sans-serif;
    }
  }
}
